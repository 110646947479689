<template>
  <div class="flex justify-center bg-white h-screen overflow-y-scroll">
    <div class="c36">
      <MainLogo class="flex justify-center items-center my-n-5xl"/>
      <div>
        <p class="c23"><span class="c3">DIBBS USER AGREEMENT</span></p>
        <p class="c7"><span class="c3"></span></p>
        <p class="c13"><span class="c2">Welcome to Dibbs! &nbsp;This User Agreement (the &ldquo;</span><span
            class="c2 c11">Agreement</span><span class="c2">&rdquo;) is between you and Collectible Holdings Inc. a Delaware
            corporation</span><span class="c2">&nbsp;</span><span class="c2">(&ldquo;</span><span
            class="c2 c11">Dibbs</span><span class="c2">&rdquo;). You may be referred to in this Agreement as the
            &ldquo;User,&rdquo; or &ldquo;you,&rdquo; and Dibbs may be referred to as &ldquo;we&rdquo; or
            &ldquo;us.&rdquo;</span><span class="c2 c11">&nbsp;</span><span class="c2">This Agreement governs your use of the
            services provided by Dibbs described below (the &ldquo;</span><span class="c2 c11">Services</span><span
            class="c0 c2">&rdquo;). </span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">By signing up to use an account through our website <router-link class="c15 c27" to="/">dibbsmarketplace.com</router-link>, our APIs, or the Dibbs
            mobile application (collectively the &ldquo;</span><span class="c2 c11">Dibbs</span><span
            class="c2">&nbsp;</span><span class="c2 c11">Platform</span><span class="c2">&rdquo;), you agree that you have
            read, understand, and accept this Agreement including the arbitration and class action waiver provisions in
            Section 8(c), as well as our </span><span class="c14 c2"><router-link class="c15 c27" to="/privacy-policy">Privacy Policy</router-link></span><span class="c2">.</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c9">
            <h1 style="display:inline"><span class="c3 c2">1.&emsp;Using our Services</span></h1>
            </li>
        </ol>
        <p class="c7 c12"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">a.&emsp;Age Requirements</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">To be eligible to use the Services, you must be at least 18 years old and
            </span><span>of the age of majority under the laws of your jurisdiction.</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">b.&emsp;Amendments and Revisions</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">We may amend or modify this Agreement at any time by posting a revised agreement on
            the Dibbs Platform and/or providing a copy to you (a &ldquo;</span><span class="c2 c11">Revised
            Agreement</span><span class="c2">&rdquo;). The Revised Agreement shall be effective as of the time it is posted.
            Your continued use of the Services after the posting of a Revised Agreement constitutes your acceptance of such
            Revised Agreement. If you do not agree with any modification, your sole and exclusive remedy is to terminate your
            use of the Services and close your account.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">c.&emsp;Registration of Dibbs Account</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">You must register for an account with Dibbs (a &ldquo;</span><span
            class="c2 c11">Dibbs Account</span><span class="c2">&rdquo;)</span><span class="c2 c11">&nbsp;</span><span
            class="c2">to use the Services. By using a Dibbs Account you agree and represent that you will use Dibbs only for
            yourself, and not on behalf of any third party, unless you have obtained prior approval from Dibbs. You are fully
            responsible for all activity that occurs using your Dibbs Account. We may, in our sole discretion, refuse to open
            a Dibbs Account, limit the number of Dibbs Accounts that you may hold, suspend or terminate any Dibbs Account,
            and/or suspend or terminate the trading of one or more Tokens in your Dibbs Account or Linked Wallet
            (collectively, your &ldquo;</span><span class="c2 c11">Dibbs Wallet</span><span class="c2">&rdquo;).</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">d.&emsp;Identity Verification</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">During registration for your Dibbs Account, you agree to provide such information as
            Dibbs may request for the purposes of identity verification and the detection of money laundering, terrorist
            financing, fraud, or any other financial crimes and permit Dibbs to keep a record of such information. Your access
            to one or more Services and the limits that apply to your use of the Services, may be altered as a result of
            information collected about you on an ongoing basis. The information requested may include certain personal
            information, including, but not limited to, your name, address, telephone number, e-mail address, date of birth,
            taxpayer identification number, a government identification, and information regarding your bank account (such as
            the name of the bank, the account type, routing number, and account number) and in some cases (where permitted by
            law), special categories of personal data, such as your biometric information. In providing this or any other
            information that may be required, you confirm that the information is accurate and authentic. You agree to update
            us if any of the information you provide changes. </span><span class="c2 c11">You authorize us to make inquiries,
            whether directly or through third parties, that we consider necessary to verify your identity or protect you
            and/or us against fraud or other financial crime, and to take action we reasonably deem necessary based on the
            results of such inquiries. When we carry out these inquiries, you acknowledge and agree that your personal
            information may be disclosed to credit reference and fraud prevention or financial crime agencies and that these
            agencies may respond to our inquiries in full. This is an identity check only and should have no adverse effect on
            your credit rating. </span><span class="c2">Further, you authorize your wireless operator to use your mobile
            number, name, address, email, network status, customer type, customer role, billing type, mobile device
            identifiers (IMSI and IMEI) and other subscriber status details, if available, solely to allow verification of
            your identity and to compare information you have provided to Dibbs with your wireless operator account profile
            information for the duration of the business relationship. See our </span><span class="c14 c2"><router-link class="c15 c27" to="/privacy-policy">Privacy Policy</router-link></span><span class="c2">&nbsp;for how we treat your data.</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">e.&emsp;Access</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0 c2">To access the Services, you must have the necessary equipment (such as a smartphone
            or computer) as well as access to the Internet. Access to the Services may become degraded or unavailable during
            times of significant volatility or volume. Although we strive to provide you with excellent service, we do not
            represent that the Dibbs Platform or Services will be available without interruption and we do not guarantee that
            any order will be executed, accepted, recorded, or remain open. You agree that Dibbs is not liable for any losses
            you may incur as a result of or associated with transaction delays or unavailability.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">f.&emsp;Email Communications from Dibbs to You</span>
            </h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c2">You agree and understand that all communication from Dibbs to you will be via email or
            another method that Dibbs may prescribe from time to time. We will use the email address on record for your Dibbs
            Account as our primary means of communicating with you. To ensure that you receive all of our communications, you
            agree to keep your email address up to date and immediately notify us if there are any changes. Delivery of any
            communication to the email address on record shall be considered valid and binding for all purposes. If any email
            communication is returned as undeliverable, we retain the right to block your Dibbs Account and access to the
            Services until you provide and confirm a valid email address.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">g.&emsp;Consent to Access, Processing and Storage of
                Your Personal Data</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">You consent to us accessing, processing and retaining any personal information you
            provide to us for the purpose of us providing the Services to you. This consent is not related to, and does not
            affect, any rights or obligations we or you have in accordance with data protection laws, privacy laws and
            regulations. You can withdraw your consent at any time by closing your account with us. However, we may retain and
            continue to process your personal information for other purposes. Please see our </span><span class="c2 c14"><router-link class="c15 c27" to="/privacy-policy">Privacy Policy</router-link></span><span class="c0 c2">&nbsp;for further information about how we process your personal data, and
            your associated rights.</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c9">
            <h1 style="display:inline"><span class="c3 c2">2.&emsp;Assets on the Dibbs Platform</span></h1>
            </li>
        </ol>
        <p class="c7 c12"><span class="c0"></span></p>
        <ol>
            <li class="c13 c20 c17"><span class="c2 c11">a.&emsp;Digital Assets and Supported Tokens</span></li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c2">The Dibbs Platform may allow you to interact with certain cryptographic protocols,
            smart contracts, or computer networks, including without limitation the </span><span class="c2">Ethereum
            network</span><span class="c2">, the EOS.IO Protocol, and the WAX Blockchain, and associated protocols and smart
            contracts (collectively, &ldquo;</span><span class="c2 c11">Distributed Ledger Technologies</span><span
            class="c2">&rdquo; or &ldquo;</span><span class="c2 c11">DLT</span><span class="c2">&rdquo;). DLT may allow for
            digital representations of value (&ldquo;</span><span class="c2 c11">Digital Assets</span><span
            class="c2">&rdquo;) to be stored or transmitted over a computer network. Digital Assets that are supported on the
            Dibbs Platform are referred to in this Agreement as &ldquo;</span><span class="c2 c11">Tokens</span><span
            class="c2">&rdquo; or &ldquo;</span><span class="c2 c11">Supported Tokens</span><span class="c2">.&rdquo; Digital
            Assets that are not supported on the Dibbs Platform are &ldquo;</span><span class="c2 c11">Unsupported
            Tokens</span><span class="c2">.&rdquo;</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0 c2">Your Dibbs Account is intended solely for proper use of Supported Tokens. Dibbs may
            in its sole discretion add or remove Digital Assets as Supported Tokens at any time, for any reason or no reason,
            at which point the Digital Asset shall cease to be a Supported Token. Do not attempt to use your Dibbs Wallet to
            store, send, request, or receive Digital Assets that are not Supported Tokens.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2 c11">You agree and understand that Dibbs makes no commitments, representations, or
            warranties to continue performing services in connection with your Item Tokens or Fractional Tokens, including
            without limitation operating the Dibbs Platform.</span><span class="c2">&nbsp;You further agree and understand
            that your Item Tokens or Fractional Tokens may be transmitted to your Linked Wallet or other wallet address
            provided by you or to you prior to any discontinuance of service, and that upon such transmission, Dibbs shall
            have no further obligation to you in connection with your Item Tokens or Fractional Tokens. Alternatively, Dibbs
            may perform a Platform Buyout of Item Tokens and/or Fractional Tokens, as discussed in greater detail below, in
            connection with any discontinuance of service. Where your Fractional Token holdings in any single Item Token are
            valued at less than one cent ($.01) at current pricing on the Dibbs Platform, these are called &ldquo;</span><span
            class="c2 c11">De Minimis Holdings</span><span class="c0 c2">.&rdquo; &nbsp;You understand that Dibbs will not
            display De Minimis Holdings in your Dibbs Wallet, and that in the event of a Platform Buyout of an Item or Item
            Token in which you have De Minimis Holdings, you will receive no consideration for your De Minimis Holdings. Dibbs
            does not control or endorse purchases or sales of Item Tokens or Fractional Tokens outside of the Dibbs Platform.
            We expressly deny any obligation to indemnify you or hold you harmless for any losses you may incur by
            transacting, or facilitating transactions outside of the Dibbs Platform.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Unless announced on the Dibbs Platform or other official public statement of Dibbs,
            Supported Tokens excludes other protocols, digital assets, and functionality that may supplement or interact with
            a Supported Token (collectively &ldquo;</span><span class="c2 c11">Unsupported Functions</span><span
            class="c2">&rdquo;). Unsupported Functions include, without limitation, side chains, forked protocols, staking,
            protocol governance, Digital Assets that are not Supported Tokens, and any unsupported smart contract
            functionality. Do not use your Dibbs Account to attempt to receive, request, send, store, interact with, or
            otherwise engage in any type of Unsupported Function - doing so may result in loss of Digital Assets. </span><span
            class="c2 c11">You acknowledge and agree that Dibbs has no liability for any losses related to Unsupported
            Functions.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">b.&emsp;Item Tokens</span>
            </h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c2">The Dibbs Platform allows you to trade Supported Tokens representing ownership of
            collectible items (each such token, an &ldquo;</span><span class="c2 c11">Item Token</span><span
            class="c2">&rdquo; and each associated collectible item an &ldquo;</span><span class="c2 c11">Item</span><span
            class="c2">&rdquo;). Item Tokens are </span><span class="c2">non-fungible</span><span class="c2">&nbsp;Digital
            Assets existing and transferable on the </span><span class="c2">WAX blockchain</span><span class="c2">. Fractions
            of Item Tokens may be represented by fungible Digital Assets using the </span><span class="c14 c2"><a class="c15 c27" href="https://atomicassets.io/" target="_blank" rel="noopener noreferrer">atomicassets standard</a></span><span class="c2">&nbsp;(fractions of Item Tokens are &ldquo;</span><span
            class="c2 c11">Fractional Tokens</span><span class="c2">&rdquo;) using an open-source smart contract</span><span
            class="c2">&nbsp;</span><span class="c2">allowing for the lockup of an Item Token, distribution of Fractional
            Tokens, and unlocking of the Item Token upon submission of 100% of its Fractional Tokens </span><span>(the
            &ldquo;</span><span class="c11">Smart Contract</span><span>&rdquo;)</span><span>. </span><span class="c2">Pursuant
            to the Smart Contract, and subject to limitations set forth in this Agreement, an owner of 100% of an Item Token
            </span><span class="c2">may take possession of the Item Token by interacting with the Smart Contract over the WAX
            blockchain (through an On-Chain Transaction)</span><span class="c2">.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Items and Supported Tokens on the Dibbs Platform are subject to the terms and
            conditions set forth in the </span><span class="c2">Dibbs <span class="c14">
            <router-link class="c15 c27" to="/consignment-agreement">Consignment Agreement</router-link></span></span><span class="c0 c2">, which
            includes the following:</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c13 c17 c12"><span class="c2">title to the Item remains with the seller (the
                &ldquo;</span><span class="c2 c11">Consignor</span><span class="c0 c2">&rdquo;) until the Item Token or
                Fractional Tokens are purchased from the Consignor;</span></li>
            <li class="c13 c17 c12"><span class="c0 c2">the Consignor makes representations set forth in Section 9
                of the Consignment Agreement;</span></li>
            <li class="c13 c17 c12"><span class="c0 c2">the Consignor executes a custody agreement with the
                custodian; and</span></li>
            <li class="c13 c17 c12"><span class="c0 c2">the Consignor agrees that, pursuant to the terms of the
                custody agreement, the Item shall be released by the custodian upon submission of the Item Token to the
                custodian.</span></li>
        </ul>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">You understand that Dibbs does not control the WAX blockchain or any other DLT
            network, or the </span><span class="c2">atomicassets</span><span class="c2">&nbsp;token standard</span><span
            class="c2">. You also agree and understand that Dibbs may take actions with respect to your Item Tokens and
            Fractional Tokens in order to promote the security and efficiency of the Dibbs Platform, including without
            limitation by migrating your Supported Tokens held by Dibbs to another DLT network, burning and re-issuing
            Fractional Tokens and Item Tokens, or unlocking an Item Token from the Smart Contract and depositing it into a new
            smart contract.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c19 c2 c11">c.&emsp;Identical Items</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span>Where we have two or more of the same Item (typically a sports card or other trading card), with
            the same grade assigned by the same grading service, held by the same Custodian, we will consider such Items
            &ldquo;</span><span class="c11">Identical Items</span><span>.&rdquo; Item Tokens of Identical Items
            (&ldquo;</span><span class="c11">Identical Item Tokens</span><span>&rdquo;) may be treated as interchangeable by
            Dibbs and the Dibbs Platform. Accordingly, if you own the equivalent of 100% of the Fractional Tokens associated
            with one Identical Item, and you seek to take possession pursuant to Section 3(b) of this Agreement, you may be
            issued any one of the Identical Items or Identical Item Tokens, at Dibbs&rsquo; sole discretion.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">d.&emsp;Collections</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">From time to time, Dibbs may support the purchase of Fractional Tokens associated with
            multiple Item Tokens in a single purchase (such offerings being, &ldquo;</span><span
            class="c2 c11">Collections</span><span class="c2">&rdquo;). Collections will be assembled in Dibbs&rsquo; sole
            discretion, based on factors including, without limitation, the market value, numerosity, and liquidity of the
            associated Item Tokens</span><span class="c0 c2">. You will have the ability to sell Fractional Tokens purchased
            through a Collection individually at your discretion, just as if you had purchased the Fractional Tokens in
            separate transactions. </span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c0 c2">All data related to the Collection is for informational purposes only. You
            understand and agree that Collections are offered solely for your convenience, to allow you to purchase multiple
            Fractional Tokens in a single transaction, and that a Collection is not an offer to purchase any single Item,
            security, investment or financial instrument. You further understand and agree that the inclusion or exclusion of
            any Item Token in a Collection by Dibbs is not collecting advice or any other type of advice, whether concerning
            the marketability or value of the Item Tokens included in a Collection or otherwise.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">e.&emsp;Ownership and Control of Tokens</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span>None of the Tokens in your Dibbs Wallet are the property of, or shall or may be loaned to, Dibbs.
            </span><span class="c2">As the owner of Tokens in your Dibbs Wallet, you bear all risk of loss associated with the
            Items, Item Tokens, and Fractional Tokens.</span><span>&nbsp;Dibbs may not grant a security interest in the
            Digital Assets held in your </span><span>Dibbs</span><span>&nbsp;Wallet. Except as required by a facially valid
            court order, or except as provided herein, Dibbs will not direct the sale, transfer, loan, hypothecation, or
            alienation of Tokens in your </span><span>Dibbs</span><span>&nbsp;Wallet unless instructed by you. </span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c9">
            <h1 style="display:inline"><span class="c3 c2">3.&emsp;Transactions on the Dibbs Platform</span></h1>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c2 c3">a.&emsp;Purchases, Sales, and Buyout Transactions</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c2">When you transact on the Dibbs Platform, Dibbs transacts on your behalf to facilitate
            your requested transaction between you and one or more other Dibbs users. When you execute a purchase of Tokens (a
            &ldquo;</span><span class="c2 c11">Purchase</span><span class="c2">&rdquo;), you do so using Dibbs Balance
            </span><span class="c2">in your Linked Wallet</span><span class="c2">. </span><span class="c2">Dibbs may choose not
            to process transactions where Purchase funds have not yet settled to the Linked Wallet</span><span class="c2">.
            When you execute a sale of Tokens (a &ldquo;</span><span class="c2 c11">Sale</span><span class="c2">&rdquo; or
            &ldquo;</span><span class="c2 c11">Sell</span><span class="c0 c2">&rdquo;), Sale proceeds, net of certain Fees,
            are deposited into your Linked Wallet as Dibbs Balance.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">In</span><span class="c2">&nbsp;addition to ordinary transactions to Purchase or Sell
            Fractional Tokens, Dibbs may allow users to buy all Fractional Tokens associated with certain Item Tokens without
            other holders of Fractional Tokens associated with that Item Token executing a Sale (each such transaction is a
            &ldquo;</span><span class="c2 c11">User Buyout</span><span class="c2">&rdquo;).</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Where enabled, a User Buyout can only be exercised by a user who first acquires 51% of
            the Fractional Tokens associated with the Item Token. The Item Token&rsquo;s sale price in a User Buyout
            transaction will be 133% of the Market Price (the &ldquo;</span><span class="c2 c11">User Buyout
            Premium</span><span class="c2">&rdquo;), with payment of the sale price being made pro rata to holders of
            Fractional Tokens being purchased in the User Buyout. Additional fees may apply to User Buyout transactions. See
            our </span><span class="c2">Fee Schedule</span><span class="c2">&nbsp;page</span><span class="c0 c2">&nbsp;for
            information on fees associated with User Buyout transactions.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">For the purpose of User Buyouts, &ldquo;</span><span class="c2 c11">Market
            Price</span><span class="c2">&rdquo; shall mean the higher of: 1) the twenty-four hour high price on Dibbs; 2) the
            30-day volume weighted average price on the Dibbs Platform as calculated by Dibbs (&ldquo;</span><span
            class="c2 c11">VWAP</span><span class="c2">&rdquo;); and 3) the 90-day VWAP on the Dibbs Platform.</span><span
            class="c0 c2">&nbsp;</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2 c11">You understand and agree that, where enabled by Dibbs in its sole discretion, User
            Buyouts may allow your Fractional Tokens to be purchased from you by another user at the price determined by the
            User Buyout Premium, and that such User Buyouts may occur without you executing a Sale.</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">You understand and agree that Dibbs may add or remove tokens from its list of
            Supported Tokens at any time in its sole discretion, including by ceasing to support tokens you hold in your Dibbs
            Wallet, as set forth in Section 2(a) of this Agreement. Where Fractional Tokens in your Dibbs Wallet are to become
            Unsupported Tokens, Dibbs may allow you to take possession of the Fractional Tokens by sending them to a digital
            wallet other than your Dibbs Wallet prior to them becoming Unsupported Tokens; alternatively, in Dibbs sole
            discretion, you agree that Dibbs may choose to purchase such Fractional Tokens from you at the Platform Buyout
            Price (as defined below) with your consent, which you hereby grant (a &ldquo;</span><span class="c2 c11">Platform
            Buyout</span><span class="c2">&rdquo;). Where Dibbs chooses to exercise a Platform Buyout, the purchase price
            payable from Dibbs to you (the &ldquo;</span><span class="c2 c11">Platform Buyout Price</span><span
            class="c0 c2">&rdquo;) will be the higher of: 1) the 30-day VWAP on the Dibbs Platform as calculated by Dibbs; and
            2) the reasonable market value as determined by a third-party price guide or similar service, with such
            third-party and manner of valuation to be selected by Dibbs. You understand and agree that the sole consideration
            due from Dibbs to you in the event of a Platform Buyout shall be the Platform Buyout Price. In the event that your
            holdings in an Item Token are De Minimis Holdings, you understand that you will not receive any consideration in a
            Platform Buyout of that Item Token.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Purchases, Sales, and User Buyouts are collectively referred to herein as
            &ldquo;</span><span class="c2 c11">Token Transactions</span><span class="c0 c2">.&rdquo; Any Token Transaction
            must follow the relevant instructions on the Dibbs Platform. If Dibbs cannot complete your Token Transaction for
            any reason (such as price movement, market latency, inability to find a counterparty for your transaction, or
            order size), Dibbs will reject the order and notify you of such rejection. </span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">b.&emsp;Taking Possession</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c18"></span></p>
        <p class="c13"><span class="c2">If you own 100% of an Item Token offered on the Dibbs Platform, you may be given the
            option to take possession of either the Item or the Item Token using the Dibbs Platform. If you choose to take
            possession of the Item or the Item Token, Dibbs may charge fees in connection with your taking possession,
            including processing fees and network fees, each of which is set forth in greater detail in our </span><span
            class="c2">Fee Schedule</span><span class="c0 c2">.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">c.&emsp;Fees</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">You understand and agree that Dibbs may charge you fees in connection with your use of
            the Dibbs Platform, including fees associated with Purchases and Sales (&ldquo;</span><span class="c2 c11">Trading
            Fees</span><span class="c2">&rdquo;), and other charges and fees set forth</span><span class="c2">&nbsp;on our
            </span><span class="c2">Fee Schedule</span><span class="c2">&nbsp;</span><span class="c2">page</span><span
            class="c2">&nbsp;(collectively, &ldquo;</span><span class="c2 c11">Fees</span><span class="c2">&rdquo;). By using
            the Services you agree to pay all applicable Fees. Dibbs reserves the right to adjust its pricing and Fees and any
            applicable waivers at any time. We will notify you of the pricing and Fees which apply to your transaction when
            you authorize the transaction and in each receipt we issue to you. We may charge Fees to cover network fees
            associated with certain Token Transactions (for example, miner fees or &ldquo;gas&rdquo; for on-chain transactions
            using Distributed Ledger Technology (such transactions are &ldquo;</span><span class="c2 c11">On-Chain
            Transactions</span><span class="c2">&rdquo;)). You understand that the amount of such fees will fluctuate based on
            network conditions and other factors beyond our control. We will calculate such network fees </span><span
            class="c2">at our</span><span class="c0 c2">&nbsp;discretion, and will notify you of the network fee at or before
            the time you authorize the Token Transaction. Any bank fees charged to Dibbs may be netted out of transfers to or
            from Dibbs. You are responsible for paying any additional fees charged by your financial service provider. We will
            not request a transfer if associated bank fees exceed the value of the transfer. You may be required to deposit
            additional USD to cover bank fees if you desire to complete such a transfer.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">d.&emsp;Unauthorized or Incorrect Transactions</span>
            </h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">When a transaction occurs using your credentials, we will assume that you authorized
            the transaction, unless you notify us otherwise. If you believe you did not authorize a particular transaction or
            that a transaction was incorrectly carried out, you must contact us as soon as possible at </span><span
            class="c22"><a class="c15 c27" href="mailto:notice@dibbs.io">notice@dibbs.io</a></span><span class="c2">. It is
            important that you check your Dibbs Wallet balances and transaction history regularly, and you agree to notify us
            as soon as possible of any unauthorized or incorrect transactions from your </span><span>Dibbs</span><span
            class="c0 c2">&nbsp;Wallet.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">e.&emsp;Balances and Transaction History</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">You will be able to see your </span><span>Dibbs</span><span class="c0 c2">&nbsp;Wallet
            balances using the Dibbs Platform. You can also see your transaction history using the Dibbs Platform, including
            (i) the amount of each transaction, (ii) any Fees charged by Dibbs (excluding any spread over the prevailing
            market rate on the Dibbs&rsquo; Platform), and (iii) the date of each transaction.</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">f.&emsp;Reversals and Cancellations</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">You cannot cancel, reverse, or change any transaction marked as complete or pending.
            If your payment is not successful, if your payment method has insufficient funds, or if you reverse a payment made
            from Dibbs Balance in your Linked Wallet, you authorize Dibbs to cancel the transaction or to debit your other
            payment methods, including your </span><span>Dibbs</span><span class="c0 c2">&nbsp;Wallet balances or other linked
            accounts, in any amount necessary to complete the transaction. You are responsible for maintaining an adequate
            balance and/or sufficient credit limits in order to avoid overdraft, non-sufficient funds, or similar fees charged
            by your payment provider. We reserve the right to refuse to process, or to cancel or reverse, any transaction,
            even after funds have been debited from your account(s), if we suspect the transaction involves (or has a high
            risk of involvement in) money laundering, terrorist financing, fraud, or any other type of financial crime; in
            response to a subpoena, court order, or other government order; if we reasonably suspect that the transaction is
            erroneous; or if Dibbs suspects the transaction relates to Prohibited Use or a Prohibited Business as set forth
            below. In such instances, Dibbs may reverse the transaction and we are under no obligation to allow you to
            reinstate a purchase or sale order at the same price or on the same terms as the canceled transaction.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">g.&emsp;Transactions With Third-Parties</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">You agree and understand that Dibbs does not control, and shall not be liable for, the
            delivery, quality, safety, legality, or other aspect of any Item, Item Token, or Fractional Token that you may
            purchase using our Services. If you believe a third party has used the Services in a misleading or inappropriate
            manner, you may notify Dibbs Support at </span><span class="c22"><a class="c15 c27"
                href="mailto:notice@dibbs.io">notice@dibbs.io</a></span><span class="c2">.</span></p>
        <h1 class="c13 c26"><span class="c3 c2"></span></h1>
        <ol>
            <li class="c9">
            <h1 style="display:inline"><span class="c3 c2">4.&emsp;Linked Wallets</span></h1>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">a.&emsp;Dibbs Balance</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span>You may be allowed to make funds available for use on the Dibbs Platform (such funds are your
            &ldquo;</span><span class="c11">Dibbs Balance</span><span>&rdquo;). When you purchase Dibbs Balance, the sale is
            made by Circle Internet Financial, Inc. (&ldquo;</span><span class="c11">Circle</span><span>&rdquo;). Your Dibbs
            Balance is held in a linked wallet hosted by Circle (your &ldquo;</span><span class="c11">Linked
            Wallet</span><span>&rdquo;). By purchasing Dibbs Balance, you agree to Circle&rsquo;s </span><span>terms and
            conditions</span><span>&nbsp;(the &ldquo;</span><span class="c11">Circle Terms</span><span
            class="c0">&rdquo;).</span></p>
        <p class="c13"><span class="c0">&nbsp;</span></p>
        <p class="c13"><span>Only Dibbs Balance can be used for Purchases on the Dibbs Platform. </span><span>When you use a
            bank account to purchase Dibbs Balance, we may require the name on the bank account to match the name verified on
            your Dibbs Account. ACH or wire transfers may be classified by Dibbs as &ldquo;pending&rdquo; until the transfer
            has cleared. We may place restrictions on your use of such Dibbs Balance purchases until they are cleared,
            including by preventing your withdrawal of Dibbs Balance from your Dibbs Account until they have cleared.</span>
        </p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">b.&emsp;Control of Linked Wallets</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span>Your Dibbs Balance is</span><span>&nbsp;held by Circle on your behalf pursuant to Circle&rsquo;s
            terms and conditions. At no time will Dibbs be the holder or owner of the Dibbs Balance in your Linked Wallet. You
            maintain a customer relationship with Circle regarding your Linked Wallet.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">You agree and understand that Dibbs&rsquo; role is only as a facilitator that accesses
            your Linked Wallet through the API integration made available through Circle, such that Dibbs can send deposit,
            withdrawal, and transfer instructions on your behalf in connection with your trading activities on the Dibbs
            Platform.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">c.&emsp;User Authorizations</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">You hereby authorize Dibbs and its employees and agents, in relation to the Linked
            Wallet and your utilization of the Services, to, among other things:</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c13 c17 c12"><span>Access and view the Dibbs Balance and all other
                t</span><span>ransaction</span><span class="c0">&nbsp;information (including transaction history) relating to
                your Linked Wallet for the purpose of reconciliation and computation of amounts due to or from you arising from
                transactions on the Dibbs Platform;</span></li>
        </ul>
        <p class="c7 c12"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c13 c17 c12"><span class="c0">Instruct Circle to effect transfers of Dibbs Balance to/from
                the Linked Wallet;</span></li>
        </ul>
        <p class="c7 c12"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c13 c17 c12"><span>Instruct Circle to effect credit and debit of the Linked Wallet balance in
                relation to transactions which have been executed on the Dibbs Platform or for Fees and charges arising from
                transactions conducted through the Dibbs Platform, or in relation to transactions which have been
            </span><span>reversed</span><span class="c0">&nbsp;pursuant to the User Agreement;</span></li>
        </ul>
        <p class="c7 c12"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c13 c17 c12"><span class="c0">Freeze (or coordinate with Circle to freeze) further credit or
                debit to or from your Dibbs Wallet due to your breach of the User Agreement or breach of applicable law, or if
                there is a suspicion of money laundering/terrorism financing, or if there are breaches of anti-money
                laundering/countering the financing of terrorism policies and procedures; and</span></li>
        </ul>
        <p class="c7 c12"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c13 c12 c17"><span class="c0">Use any of your data or information obtained through Circle for
                purposes of effecting transactions on the Dibbs Platform or otherwise and/or share any of your data or
                information with Circle for purposes of operating and maintaining the Linked Wallet.</span></li>
        </ul>
        <p class="c7 c12"><span class="c0"></span></p>
        <p class="c13"><span class="c0">You may only withdraw or revoke these authorizations upon closure of your Dibbs
            Account in accordance with the User Agreement. Should you instruct Circle to revoke any of these authorizations
            prior to the closure of your Dibbs Account, then you will not be entitled to continue using any of the Services,
            and Dibbs may immediately terminate and/or cancel your Dibbs Account.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">d.&emsp;Transfer of Dibbs Balance from Linked
                Wallet</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span>You may initiate a transfer of Dibbs Balance from your Linked Wallet (a &ldquo;</span><span
            class="c11">Dibbs Balance</span><span class="c11">&nbsp;Withdrawal</span><span>&rdquo;) </span><span>in accordance
            with the instructions on the Dibbs Site</span><span class="c0">&nbsp;(or such other method that Dibbs may
            prescribe from time to time), and confirming your instruction (through the method Dibbs may prescribe from time to
            time) to withdraw the Dibbs Balance.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">You agree and acknowledge that any request for a Dibbs Balance Withdrawal will only be
            effected after such time as Dibbs may require to conduct the necessary verification and reconciliation procedures.
            Under no circumstances shall any of (a) Dibbs, (b) Dibbs&rsquo; shareholders, joint venture parties, affiliates
            and related corporations and entities, and (c) the officers, directors, employees, representatives, agents,
            suppliers and service providers of all of the persons and entities described in (a) and (b) be responsible or
            liable for any direct or indirect losses (including loss of profits, business or opportunities), damages or costs
            suffered by you or any other person or entity, arising from any delay in effecting a Dibbs
            BalanceWithdrawal.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">e.&emsp;Minimum Amount</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span>You shall maintain the minimum Dibbs Balance amount in the Linked Wallet as may be required by
            Dibbs and/or Circle from time to time in their respective discretion (the &ldquo;</span><span class="c11">Minimum
            Amount</span><span class="c0">&rdquo;). </span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span>If Dibbs determines that the Minimum Amount shall be increased, then you shall purchase such
            additional Dibbs Balance as may be necessary within three (3) Business Days to satisfy the increased Minimum
            Amount. Dibbs shall have the right to suspend or terminate the Services available to you should you fail to do
            so.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">f.&emsp;Data Protection</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span>You agree and accept </span><span>our </span><span class="c14">
            <router-link class="c15 c27" to="/privacy-policy">Privacy Policy</router-link></span><span>.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">g.&emsp;Representations and Warranties Relating to
                Linked Wallets</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">You represent and warrant to Dibbs that you expressly accept the Circle Terms in
            relation to the operation of the Linked Wallet and you understand that Circle is providing their products and
            services (including but not limited to all services relating to the Linked Wallet) to you subject to the Circle
            Terms.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">You hereby represent and warrant to Dibbs that at all times you will:</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c13 c17 c12"><span class="c0">Comply with the Circle Terms; </span></li>
        </ul>
        <p class="c7 c12"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c13 c17 c12"><span class="c0">Not use the Services in violation of the Circle Terms;
                and</span></li>
        </ul>
        <p class="c7 c12"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c13 c17 c12"><span>Not use the Services, or the products or services provided by Circle, in a
                manner that is fraudulent, unlawful, deceptive or abusive.</span></li>
        </ul>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c9">
            <h1 style="display:inline"><span class="c3 c2">5.&emsp;Token Transfers</span></h1>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">a.&emsp;In General</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Dibbs may</span><span class="c2">&nbsp;enable you to send certain Supported Tokens to,
            and request, receive, and store Supported Tokens from, third parties by giving instructions through the Dibbs
            Platform. Any transfer of Supported Tokens to or from your other Digital Asset wallets (including wallets other
            than Linked Wallets), and to or from third parties is a &ldquo;</span><span class="c2 c11">Token
            Transfer</span><span class="c0 c2">.&rdquo;</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">b.&emsp;Pending Transactions</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c0 c2">Once a Token Transfer is submitted to a Digital Asset network, the transaction will
            be unconfirmed and remain in a pending state for a period of time sufficient for confirmation of the transaction
            by the Digital Asset network. A Token Transfer is not complete while it is in a pending state. Pending Token
            Transfers that are initiated from the Dibbs Platform will reflect a pending transaction status and are not
            available to you for use while the transaction is pending.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">c.&emsp;Inbound Transfers</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">When you or a third party sends Digital Assets to a Dibbs Wallet from a wallet that is
            not a Dibbs Wallet (&ldquo;</span><span class="c2 c11">Inbound Transfers</span><span class="c0 c2">&rdquo;), the
            person initiating the transaction is solely responsible for executing the transaction properly, which may include,
            among other things, payment of sufficient network or miner&rsquo;s fees in order for the transaction to be
            successful. Insufficient network fees may cause an Inbound Transfer to remain in a pending state outside of
            Dibbs&rsquo; control and we are not responsible for delays or loss incurred as a result of an error in the
            initiation of the transaction and have no obligation to assist in the remediation of such transactions. We
            recommend customers send a small amount of a Supported Token as a test prior to initiating a send of a significant
            amount of the Supported Token. Dibbs may from time to time begin supporting or cease supporting Tokens in keeping
            with this User Agreement.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">d.&emsp;Outbound Transfers</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">When you send Tokens from your Linked Wallet to an external wallet
            (&ldquo;</span><span class="c2 c11">Outbound Transfers</span><span class="c2">&rdquo;), such transfers are
            executed at your instruction by Dibbs, subject to any conditions and limitations under this Agreement, as well as
            the terms and conditions of t</span><span>he Circle Terms. Y</span><span class="c0 c2">ou should verify all
            transaction information prior to submitting instructions to us. Dibbs shall bear no liability or responsibility in
            the event you enter an incorrect blockchain destination address. We do not guarantee the identity or value
            received by a recipient of an Outbound Transfer. Token Transfers cannot be reversed once they have been broadcast
            to the relevant Digital Asset network, although they may be in a pending state, and designated accordingly, while
            the transaction is processed by network operators. Dibbs does not control the Digital Asset network and makes no
            guarantees that a Token Transfer will be confirmed by the network. We may refuse to process or cancel any requests
            for Outbound Digital Asset Transfers as required by law or any court or other authority to which Dibbs is subject
            in any jurisdiction. Additionally, we may require you to wait some amount of time after completion of a
            transaction before permitting you to further use the Services and/or before permitting you to engage in
            transactions beyond certain volume limits.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c9">
            <h1 style="display:inline"><span class="c3 c2">6.&emsp;Data Protection and Security</span></h1>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">a.&emsp;Personal Data</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">You acknowledge that we may process personal data in relation to you (if you are an
            individual), and personal data that you have provided or in the future provide to us in relation to your employees
            and other associated or other individuals, in connection with this Agreement, or the Services. Accordingly, you
            represent and warrant that: (i) your disclosure to us of any personal data relating to individuals other than
            yourself was or will be made in accordance with all applicable data protection and data privacy laws, and those
            data are accurate, up to date and relevant when disclosed; (ii) before providing any such personal data to us, you
            have read and understood our </span><span class="c14 c2"><router-link class="c15 c27" to="/privacy-policy">Privacy Policy</router-link></span><span class="c2">, and, in the case of personal data relating to an individual other than
            yourself, have (or will at the time of disclosure have) provided a copy of that </span><span class="c2">Privacy
            Policy</span><span class="c2">&nbsp;(as amended from time to time), to that individual; and (iii) if from time to
            time we provide you with a replacement version of the </span><span class="c2">Privacy Policy</span><span
            class="c0 c2">, you will promptly read that notice and provide a copy to any individual whose personal data you
            have provided to us.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">b.&emsp;Security Breach</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">If you suspect that your Dibbs Account or any of your security details have been
            compromised or if you become aware of any fraud or attempted fraud or any other security incident (including a
            cyber-security attack) affecting you and / or Dibbs (together a &ldquo;</span><span class="c2 c11">Security
            Breach</span><span class="c2">&rdquo;), you must notify Dibbs as soon as possible by email at
            </span><span>security@dibbs.io</span><span class="c0 c2">&nbsp;and continue to provide accurate and up to date
            information throughout the duration of the Security Breach. You must take any steps that we reasonably require to
            reduce, manage or report any Security Breach. Failure to provide prompt notification of any Security Breach may be
            taken into account in our determination of the appropriate resolution of the matter.</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c9">
            <h1 style="display:inline"><span class="c3 c2">7.&emsp;General Use, Prohibited Use, Death of Account
                Holder, and Termination</span></h1>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">a.&emsp;Limited License</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">We grant you a limited, nonexclusive, nontransferable license, subject to the terms of
            this Agreement, to access and use the Services, Dibbs Platform, and related content, materials, information
            (collectively, the &ldquo;</span><span class="c2 c11">Content</span><span class="c0 c2">&rdquo;) solely for
            purposes approved by Dibbs from time to time. Any other use of the Dibbs Platform or Content is expressly
            prohibited and all other right, title, and interest in the Services, Dibbs Platform and Content is exclusively the
            property of Dibbs. You agree you will not copy, transmit, distribute, sell, license, reverse engineer, modify,
            publish, or participate in the transfer or sale of, create derivative works from, or in any other way exploit any
            of the Content, in whole or in part without the prior written consent of Dibbs. &ldquo;Dibbs.io,&rdquo;
            &ldquo;Dibbs,&rdquo; and all logos related to the Services or displayed on the Dibbs Platform are either
            trademarks or registered marks of Dibbs. You may not copy, imitate or use them without Dibbs&rsquo; prior written
            consent.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">b.&emsp;Website Accuracy</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">Although we intend to provide accurate and timely information on the Dibbs Platform,
            the Dibbs Platform (including, without limitation, the Content) may not always be entirely accurate, complete or
            current and may also include technical inaccuracies or typographical errors. In an effort to continue to provide
            you with as complete and accurate information as possible, information may be changed or updated from time to time
            without notice, including without limitation information regarding our policies, products and services.
            Accordingly, you should verify all information before relying on it, and all decisions based on information
            contained on the Dibbs Platform are your sole responsibility and we shall have no liability for such decisions.
            Information provided by third parties, including historical price and supply data for Digital Assets or Tokens, is
            for informational purposes only and Dibbs makes no representations or warranties to its accuracy. Links to
            third-party materials (including without limitation websites) may be provided as a convenience but are not
            controlled by us. You acknowledge and agree that we are not responsible for any aspect of the information,
            content, or services contained in any third-party materials or on any third-party sites accessible or linked to
            the Dibbs Platform.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">c.&emsp;Third Party Applications</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0 c2">If, to the extent permitted by Dibbs from time to time, you grant express
            permission to a third party to access or connect to your Dibbs Account, either through the third party&#39;s
            product or service or through the Dibbs Platform, you acknowledge that granting permission to a third party to
            take specific actions on your behalf does not relieve you of any of your responsibilities under this Agreement.
            You are fully responsible for all acts or omissions of any third party with access to your Dibbs Account. Further,
            you acknowledge and agree that you will not hold Dibbs responsible for, and will indemnify Dibbs from, any
            liability arising out of or related to any act or omission of any third party with access to your Dibbs
            Account.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">d.&emsp;Prohibited Use and Prohibited Businesses</span>
            </h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c2">&ldquo;</span><span class="c2 c11">Prohibited Use</span><span class="c0 c2">&rdquo;
            includes any of the following: 1) unlawful activity which may violate or assist another in violating any law,
            regulation, ordinance, or sanctions program, including the Commodity Exchange Act; 2) gambling, including
            lotteries, odds making, and games of chance; 3) abusive activity, including actions that are abusive of other
            users or place an unreasonable load on our infrastructure; 4) transmitting any type of virus or harmful program to
            the Dibbs Platform; 5) attempting to gain unauthorized access to the Dibbs Platform or other users&rsquo; Dibbs
            Accounts; and 6) transferring your account access or rights to your Dibbs Account to a third party unless by
            operation of law or with the express permission of Dibbs.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">&ldquo;</span><span class="c2 c11">Prohibited Business</span><span
            class="c0 c2">&rdquo; includes any of the following: 1) dealing in counterfeit or stolen goods; 2) intellectual
            property infringement, including sales, distribution, or access to counterfeit Items or other licensed materials
            without the appropriate authorization; 3) investment and credit services; 4) debt collection services; 5) purchase
            or sale of controlled or hazardous substances and adult content or services; and 6) any business activities that
            we believe pose an elevated level of risk or violates card network or bank policies.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">In connection with your use of the Services, and your interactions with other users
            and third parties, you agree and represent that you will not engage in any </span><span class="c2">Prohibited
            Business or Prohibited Use</span><span class="c0 c2">. We reserve the right at all times to monitor, review,
            retain and/or disclose any information as necessary to satisfy any applicable law, regulation, sanctions programs,
            legal process or governmental request. We reserve the right to cancel and/or suspend your Dibbs Account without
            notice if we determine, in our sole discretion, that your Dibbs Account is associated with a Prohibited Use and/or
            a Prohibited Business.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">e.&emsp;Transaction Limits</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0 c2">The use of all Services may be subject to a limit on the amount of volume, stated
            in USD terms, you may transact or transfer in a given period (e.g., daily). Your transaction limits may vary
            depending on your payment method, verification steps you have completed, and other factors. Dibbs reserves the
            right to change applicable limits as we deem necessary in our sole discretion. </span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">f.&emsp;Suspension, Termination, and
                Cancellation</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0 c2">You may cancel your Dibbs Account at any time by withdrawing all balances and
            following the account termination procedures prescribed by Dibbs from time to time. Dibbs may refuse to cancel
            your Dibbs account until you withdraw all balances. Alternatively, in Dibbs sole discretion, where aggregate
            balances in your Dibbs Account are less than thirty (30) dollars, we may choose to cancel your Dibbs Account at
            your request, without requiring you to withdraw all balances, in which case you agree that all remaining balances
            in your Dibbs Account shall become immediately due and payable to Dibbs as an account closure fee. Upon closure of
            your Dibbs Account, you will be required to pay any outstanding amounts owed to Dibbs. You authorize us to cancel
            or suspend any pending transactions at the time of cancellation. </span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Dibbs may: (a) suspend, restrict, or terminate your access to any or all of the
            Services, and/or (b) deactivate or cancel your Dibbs Account if: (i) we are so required by a facially valid
            subpoena, court order, or binding order of a government authority; (ii) we reasonably suspect you of using your
            Dibbs Account in connection with a </span><span class="c2">Prohibited Use or Business</span><span class="c0 c2">;
            (iii) use of your Dibbs Account is subject to any pending litigation, investigation, or government proceeding
            and/or we perceive a heightened risk of legal or regulatory non-compliance associated with your Dibbs Account
            activity; (iv) our service partners are unable to support your use; or (v) you take any action that Dibbs deems as
            circumventing Dibbs&rsquo; controls, including, but not limited to, opening multiple Dibbs Accounts or abusing
            Promotional Offerings which Dibbs may offer from time to time. You acknowledge that Dibbs&rsquo; decision to take
            certain actions, including limiting access to, suspending, or closing your Dibbs Account, may be based on
            confidential criteria that are essential to Dibbs&rsquo; risk management and security protocols. You agree that
            Dibbs is under no obligation to disclose the details of its risk management and security procedures to you.</span>
        </p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">g.&emsp;Relationship of the Parties</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Dibbs is an independent contractor for all purposes. Nothing in this Agreement shall
            be deemed or is intended to be deemed, nor shall it </span><span class="c2">cause, you</span><span
            class="c0 c2">&nbsp;and Dibbs to be treated as partners, joint venturers, or otherwise as joint associates for
            profit, or either you or Dibbs to be treated as the agent of the other.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">h.&emsp;Privacy of Others; Marketing</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c0 c2">If you receive information about another user through the Services, you must keep
            the information confidential and only use it in connection with the Services. You may not disclose or distribute a
            user&#39;s information to a third party or use the information except as reasonably necessary to effectuate a
            transaction and other functions reasonably incidental thereto such as support, reconciliation and accounting
            unless you receive the user&#39;s express consent to do so. You may not send unsolicited email to a user through
            the Services.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">i.&emsp;Password Security; Contact Information</span>
            </h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0 c2">You are responsible for creating a strong password and maintaining adequate
            security and control of any and all IDs, passwords, hints, personal identification numbers (PINs), API keys or any
            other codes used to access the Services. Any loss or compromise of the foregoing information and/or your personal
            information may result in unauthorized access to your Dibbs Account or Linked Wallet by third parties and the loss
            or theft of any Digital Assets and/or Dibbs Balance held in your Linked Wallet and any associated accounts,
            including any linked bank accounts or credit cards. You are responsible for keeping your email address and
            telephone number up to date in your Account Profile in order to receive any notices or alerts that we may send
            you. Dibbs will never ask you for your IDs, passwords, or two-factor authentication codes. We assume no
            responsibility for any loss that you may sustain due to compromise of account login credentials due to no fault of
            Dibbs and/or failure to follow or act upon notices or alerts Dibbs sends to you. In the event you believe your
            Dibbs Account information has been compromised, contact Dibbs immediately at security@dibbs.io.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">j.&emsp;Taxes</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0 c2">It is your sole responsibility to determine what, if any, taxes apply to any
            transactions you conduct using the Services, and to withhold, collect, report and remit the correct amounts of
            taxes to the appropriate tax authorities. Your transaction history is available through your Dibbs Account.</span>
        </p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">k.&emsp;No Investment Advice or Brokerage</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Dibbs does not provide investment, tax, or legal advice, nor does Dibbs broker trades
            on your behalf. All Dibbs purchases and sales of Tokens on the Dibbs Platform are executed automatically based on
            your order instructions. You are solely responsible for determining whether any purchase, collecting strategy, or
            related transaction is appropriate for you. You should consult your legal or tax professional regarding your
            specific situation. Dibbs may provide informational resources about Items, athletes, sports cards, and Digital
            Assets. You agree that such informational resources, as well as the Content generally, shall not constitute
            financial or collecting advice of any sort, and you will not treat any of the Content as such. </span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0 c2">You understand and acknowledge the following concerning Items and Supported
            Tokens:</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c1"><span class="c0 c2">The value of &nbsp;Items can go up or down, and there is substantial
                risk that you could lose money trading or holding Items or Item Tokens.</span></li>
            <li class="c1"><span class="c0 c2">The price and liquidity of Items and Supported Tokens may be subject
                to large and sudden fluctuations.</span></li>
            <li class="c1"><span class="c0 c2">Legislative and regulatory changes or actions at the state, federal,
                or international level may adversely affect the use, transfer, exchange, and value of Items or Supported
                Tokens.</span></li>
            <li class="c1"><span class="c0 c2">Transactions in Digital Assets, including Supported Tokens, may be
                irreversible, and losses due to fraudulent or accidental transactions may not be recoverable.</span></li>
            <li class="c1"><span class="c0 c2">Some Token Transactions may be deemed to be made when recorded on a
                public ledger, rather than the time at which you initiate the transaction.</span></li>
            <li class="c1"><span class="c2">You and Dibbs agree that the Items, Item Tokens, and Fractional Tokens
                are not securities, and have not been registered with the U.S. Securities and Exchange Commission
                (&ldquo;</span><span class="c2 c11">SEC</span><span class="c2">&rdquo;). Dibbs is not a broker-dealer, does not
                offer securities-related services, and is not registered with the SEC.</span></li>
        </ul>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c19 c2 c11">l.&emsp;Dibbs Corporate Operations</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Dibbs may trade its own corporate funds, Supported Tokens or Items on the Dibbs
            Platform. When doing so, Dibbs&rsquo; </span><span class="c2">trades </span><span class="c2">are subject to the
            same pricing and priority as other users.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c9">
            <h1 style="display:inline"><span class="c3 c2">8.&emsp;Customer Feedback and Dispute Resolution</span>
            </h1>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">a.&emsp;Contacting Dibbs</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">You may contact us with any questions, concerns, or other feedback via our customer
            support webpage at </span><span class="c2 c27"><a class="c15 c27" href="https://dibbs.zendesk.com/" target="_blank" rel="noopener noreferrer">https://dibbs.zendesk.com/</a></span><span
            class="c0 c2">. When you contact us please provide us with your name, address, and any other information we may
            need to identify you, your Dibbs Account, and the substance of your feedback or question.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">Dibbs requires that all legal documents (including civil subpoenas, complaints, and
            small claims) be served on our registered agent for service of process. Current contact information for our
            registered agent in the state of Delaware can be found </span><span class="c2 c22"><a class="c15 c27"
                href="https://www.google.com/url?q=https://www.wolterskluwer.com/en/solutions/ct-corporation/sop-locations&amp;sa=D&amp;source=editors&amp;ust=1646772510982925&amp;usg=AOvVaw0SUdMjDyWOPMYpLYxHpauW">here</a></span><span
            class="c0 c2">. </span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">b.&emsp;Complaint Process</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0 c2">If you have a complaint concerning the Dibbs Platform, you agree to contact Dibbs
            as provided in Section 7(a) of this Agreement, and to attempt to resolve any such dispute amicably. Only after
            contacting Dibbs as set forth in Section 7(a), and only if we cannot amicably resolve the complaint, you agree to
            comply with the Dispute Resolution terms set forth below.</span></p>
        <p class="c7"><span class="c0 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">c.&emsp;Dispute Resolution</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c21"><span>Any dispute, controversy or claim arising out of or related to this Agreement (each a
            &ldquo;</span><span class="c11">Dispute</span><span>&rdquo;) </span><span class="c2">or the breach thereof shall
            be settled solely and exclusively by binding arbitration held in New York, New York, rather than in court,
            administered by JAMS and conducted in English. Any Dispute about the scope of this Agreement and/or the
            arbitrability of any particular Dispute shall be resolved in arbitration in accordance with this Section. In the
            event that you or Dibbs threatens to litigate a Dispute in court, the other party may institute arbitration to
            enjoin the filing of a complaint or petition in court. You and Dibbs agree that an arbitrator may issue all
            appropriate declaratory and injunctive relief necessary to ensure the arbitration of Disputes, and that any
            arbitration shall be kept strictly confidential.</span></p>
        <p class="c16 c25"><span class="c0"></span></p>
        <p class="c21"><span class="c0">You and Dibbs agree that the arbitrator shall have the authority to order any
            remedies, legal or equitable, which a party could obtain from a court of competent jurisdiction based on the
            claims asserted, and nothing more. The arbitrator shall not award punitive or exemplary damages to either
            party.</span></p>
        <p class="c16"><span class="c0"></span></p>
        <p class="c21"><span>You and Dibbs agree that any such arbitration shall be conducted in accordance with the then
            prevailing </span><span>JAMS Streamlined Arbitration Rules &amp; Procedures (the &ldquo;</span><span
            class="c11">Rules</span><span>&rdquo;)</span><span>, with the following exceptions to such Rules if in
            conflict:</span></p>
        <p class="c16"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c10"><span class="c0">Any arbitration must be commenced within one year after the claim or
                cause of action arises;</span></li>
            <li class="c10"><span class="c0">The arbitration shall be conducted by one neutral arbitrator;</span>
            </li>
            <li class="c10"><span class="c0">Service of a demand for arbitration via written notice constitutes
                sufficient written notice of the demand for arbitration;</span></li>
            <li class="c10"><span class="c0">Arbitration may proceed in the absence of any party if that party has
                been given notice of the arbitration, as specified in this Agreement and/or the Rules; and</span></li>
            <li class="c10"><span class="c0">Each side agrees to bear its own attorney&rsquo;s fees, costs, and
                expenses.</span></li>
        </ul>
        <p class="c21"><span class="c0">The Parties agree to abide by all decisions and awards rendered in such proceedings,
            and that such decisions and awards rendered by the arbitrator shall be final and conclusive.</span></p>
        <p class="c16"><span class="c0"></span></p>
        <p class="c21"><span class="c0">If you or Dibbs seeks emergency relief in connection with any controversy, claim, or
            dispute arising out of or relating to this Agreement or the breach thereof, the &ldquo;Emergency Relief
            Procedures&rdquo; provision of the JAMS Comprehensive Arbitration Rules &amp; Procedures, currently rule 2(c),
            shall govern. You agree and understand that this Agreement restricts you from seeking emergency relief from any
            court, including without limitation temporary restraining orders and/or preliminary injunctions, and that, to the
            extent a party breaches this Agreement by seeking such relief from a court, such party shall be responsible for
            paying the other party&rsquo;s attorneys&rsquo; fees in opposing such relief, and the arbitrator shall render an
            award of such attorneys&rsquo; fees at the earliest possible time after such fees are incurred.</span></p>
        <p class="c16"><span class="c0"></span></p>
        <p class="c21"><span class="c0">You or Dibbs may, without inconsistency with this arbitration provision, apply to any
            court for an order enforcing the arbitral award. Each party irrevocably and unconditionally waives any objection
            that it may now or hereafter have to the laying of venue of any action or proceeding relating to enforcement of
            the arbitral award in the federal or state courts located in the State of New York.</span></p>
        <p class="c16"><span class="c0"></span></p>
        <p class="c21"><span class="c0">If for any reason this arbitration clause becomes not applicable or for any other
            reason litigation proceeds in court, then you and Dibbs agree as follows:</span></p>
        <p class="c16"><span class="c0"></span></p>
        <ul class="c8 lst-kix_89pip65zn7v6">
            <li class="c10"><span class="c0">To the fullest extent permitted by applicable laws and regulations, the
                parties irrevocably waive all right to trial by jury as to any issue relating hereto in any action, proceeding,
                or counterclaim arising out of or relating to this Agreement or any other matter involving Dibbs; and</span>
            </li>
            <li class="c10"><span class="c0">The parties submit to the exclusive jurisdiction and venue of the
                federal or state courts located in New York County, New York, and agree not to institute any such action or
                proceeding in any other court in any other jurisdiction.</span></li>
        </ul>
        <p class="c21"><span class="c11">CLASS ACTION WAIVER: TO THE EXTENT PERMISSIBLE BY LAW, THE PARTIES AGREE THAT ANY
            DISPUTE SHALL BE ARBITRATED SOLELY ON AN INDIVIDUAL BASIS, AND AGREE AND UNDERSTAND THAT THIS AGREEMENT DOES NOT
            PERMIT CLASS ARBITRATION OR ANY CLAIMS BROUGHT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
            ARBITRATION PROCEEDING. THE ARBITRAL TRIBUNAL MAY NOT CONSOLIDATE MORE THAN ONE USER&rsquo;S CLAIMS AND MAY NOT
            OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. YOU ACKNOWLEDGE THAT, BY AGREEING TO
            THESE TERMS, YOU AND DIBBS ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY AND THE RIGHT TO PARTICIPATE IN A CLASS
            ACTION</span></p>
        <p class="c16"><span class="c0"></span></p>
        <p class="c21"><span class="c0">In the event the prohibition on class arbitration is deemed invalid or unenforceable,
            then you and Dibbs agree and understand that the remaining portions of these arbitration provisions in this
            agreement will remain in full force and effect. You and Dibbs agree that this section of the Agreement has been
            included to rapidly and inexpensively resolve any disputes with respect to the matters described herein, and that
            this section shall be grounds for dismissal of any court action commenced with respect to a dispute arising out of
            such matters. A printed version of this Agreement shall be admissible in judicial or administrative
            proceedings.</span></p>
        <p class="c16"><span class="c0"></span></p>
        <ol>
            <li class="c9">
            <h1 style="display:inline"><span class="c3 c2">9.&emsp;General Provisions</span></h1>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">a.&emsp;Operation of Digital Asset Protocols</span>
            </h2>
            </li>
        </ol>
        <p class="c7"><span class="c0 c2"></span></p>
        <p class="c13"><span class="c2">We do not own or control the DLT, including the </span><span>Smart
            Contract</span><span class="c2">&nbsp;and other network functionality by which Supported Tokens may be stored or
            transmitted.</span><span class="c2">&nbsp;Generally, the underlying protocols are open source, and anyone can use,
            copy, modify, and distribute them. We assume no responsibility for the operation of DLT, and we are not able to
            guarantee the functionality or security of DLT. In particular, the protocols may be subject to sudden changes in
            operating rules (including forks). Any such operating changes may materially affect the availability, value,
            functionality, and/or the name of any Digital Asset stored in your Dibbs Wallet. Dibbs does not control the timing
            and features of these material operating changes. It is your responsibility to make yourself aware of upcoming
            operating changes and consider publicly available information and information that may be provided by Dibbs in
            determining whether to continue to use the Dibbs Platform. In the event of any such operational change, Dibbs
            reserves the right to take such steps as may be necessary to protect the security and safety of your Tokens,
            including temporarily suspending operations for the involved Token(s), and other necessary steps. Dibbs will use
            its best efforts to provide you notice of its response to any material operating change, however, such changes are
            outside of our control and may occur without notice to Dibbs. Dibbs&rsquo; response to any material operating
            change is subject to its sole discretion and includes deciding not to support any affected Digital Asset, fork, or
            other actions. </span><span class="c2 c11">You acknowledge and accept the risks of operating changes to Digital
            Asset protocols and agree that Dibbs is not responsible for such operating changes and not liable for any loss of
            value you may experience as a result of such changes in operating rules. You acknowledge and accept that Dibbs has
            sole discretion to determine its response to any operating change, and that we have no responsibility to assist
            you with Unsupported Functions.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">b.&emsp;Computer Viruses</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">We shall not bear any liability, whatsoever, for any damage or interruptions caused by
            any computer viruses or other malicious code that may affect your computer or other equipment, or any phishing,
            spoofing or other attack. SMS and email services are vulnerable to spoofing and phishing attacks and should use
            care in reviewing messages purporting to originate from Dibbs. Only log into your Dibbs Account directly through
            the Dibbs Platform.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">c.&emsp;Release and Indemnification</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0">If you have a dispute with one or more users of the Services, you release Dibbs, its
            affiliates and service providers, and each of their respective officers, directors, agents, joint venturers,
            employees and representatives from any and all claims, demands and damages (actual and consequential) of every
            kind and nature arising out of or in any way connected with such disputes. You agree to indemnify and hold Dibbs,
            its affiliates and service providers, and each of its or their respective officers, directors, agents, joint
            venturers, employees and representatives, harmless from any claim or demand (including attorneys&rsquo; fees and
            any fines, fees or penalties imposed by any regulatory authority) arising out of or related to your breach of this
            Agreement or your violation of any law, rule or regulation, or the rights of any third party.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">d.&emsp;Limitation of Liability; No Warranty</span>
            </h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0">IN NO EVENT SHALL DIBBS, ITS AFFILIATES AND SERVICE PROVIDERS, OR ANY OF THEIR
            RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES OR REPRESENTATIVES, BE LIABLE (A) FOR ANY
            AMOUNT GREATER THAN THE VALUE OF THE SUPPORTED TOKENS ON DEPOSIT IN YOUR LINKED WALLET OR (B) FOR ANY LOST
            PROFITS, DIMINUTION IN VALUE OR BUSINESS OPPORTUNITY, ANY LOSS, DAMAGE, CORRUPTION OR BREACH OF DATA OR ANY OTHER
            INTANGIBLE PROPERTY OR ANY SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED IN
            CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED OR
            UNAUTHORIZED USE OF THE DIBBS PLATFORM OR THE SERVICES, OR THIS AGREEMENT, EVEN IF AN AUTHORIZED REPRESENTATIVE OF
            DIBBS HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE
            FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE, EXCEPT TO THE EXTENT OF A FINAL JUDICIAL
            DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF DIBBS&rsquo; GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR
            INTENTIONAL VIOLATION OF LAW. </span></p>
        <p class="c13"><span class="c0">&nbsp;</span></p>
        <p class="c13"><span class="c0">THE SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS
            WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, DIBBS SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. DIBBS DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO
            THE DIBBS PLATFORM, ANY PART OF THE SERVICES, OR ANY OF THE CONTENT CONTAINED THEREIN, WILL BE CONTINUOUS,
            UNINTERRUPTED, TIMELY, OR ERROR-FREE. DIBBS DOES NOT GUARANTEE THAT ANY ORDER WILL BE EXECUTED, ACCEPTED, RECORDED
            OR REMAIN OPEN. EXCEPT FOR THE EXPRESS STATEMENTS SET FORTH IN THIS AGREEMENT, YOU HEREBY ACKNOWLEDGE AND AGREE
            THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR UNDERSTANDING, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR
            USE AND ACCESS OF THE SERVICES AND DIBBS PLATFORM. WITHOUT LIMITING THE FOREGOING, YOU HEREBY UNDERSTAND AND AGREE
            THAT DIBBS WILL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO: (A) ANY INACCURACY, DEFECT
            OR OMISSION OF DIGITAL ASSET PRICE DATA, (B) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, OR (C)
            INTERRUPTION IN ANY SUCH DATA.</span></p>
        <p class="c13"><span class="c0">&nbsp;</span></p>
        <p class="c13"><span class="c0">Dibbs makes no representations about the accuracy, order, timeliness or completeness
            of historical Digital Asset price data available on the Dibbs Platform. Dibbs will make reasonable efforts to
            ensure that requests for electronic debits and credits involving bank accounts, credit cards, and check issuances
            are processed in a timely manner but Dibbs makes no representations or warranties regarding the amount of time
            needed to complete processing which is dependent upon many factors outside of our control.</span></p>
        <p class="c13"><span class="c0">&nbsp;</span></p>
        <p class="c13"><span>IF YOU ARE A NEW JERSEY RESIDENT, the provisions of this Section 9(d) are intended to apply only
            to the extent permitted under New Jersey law.</span></p>
        <p class="c7"><span class="c3 c2"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">e.&emsp;Entire Agreement</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span>This Agreement, the <span class="c14">
            <router-link class="c15 c27" to="/consignment-agreement">Consignment Agreement</router-link></span>, the </span><span class="c14"><router-link class="c15 c27" to="/privacy-policy">Privacy Policy</router-link></span><span>, and any </span><span>appendices</span><span class="c0">&nbsp;incorporated by reference
            herein comprise the entire understanding and agreement between you and Dibbs as to the subject matter hereof, and
            supersede any and all prior discussions, agreements and understandings of any kind (including without limitation
            any prior versions of this Agreement), and every nature between and among you and Dibbs. Section headings in this
            Agreement are for convenience only and shall not govern the meaning or interpretation of any provision of this
            Agreement.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">f.&emsp;Amendments</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0">Dibbs may amend or modify this Agreement by posting on the Dibbs Platform or emailing
            to you the Revised Agreement, and the Revised Agreement shall be effective at such time. If you do not agree with
            any such modification, your sole and exclusive remedy is to terminate your use of the Services and close your
            Dibbs Account. You agree that we shall not be liable to you or any third party for any modification or termination
            of the Services, or suspension or termination of your access to the Services, except to the extent otherwise
            expressly set forth herein. If the Revised Agreement includes a material change, we will endeavor to provide you
            advanced notice via the Dibbs Platform and/or email before the material change becomes effective.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">g.&emsp;Assignment</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">Except as explicitly provided in this Agreement, you may not assign any rights and/or
            licenses granted under this Agreement. We reserve the right to assign our rights without restriction, including
            without limitation to any Dibbs affiliates or subsidiaries, or to any successor in interest of any business
            associated with the Services. Any attempted transfer or assignment in violation hereof shall be null and void.
            Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their successors and
            permitted assigns.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">h.&emsp;Severability</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c3 c2"></span></p>
        <p class="c13"><span class="c0">If any provision of this Agreement is determined to be invalid or unenforceable under
            any rule, law, or regulation of any local, state, or federal government agency, such provision will be changed and
            interpreted to accomplish the objectives of the provision to the greatest extent possible under any applicable law
            and the validity or enforceability of any other provision of this Agreement shall not be affected.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">i.&emsp;Change of Control</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">In the event that Dibbs is acquired by or merged with a third party entity, we reserve
            the right, in any of these circumstances, to transfer or assign the information we have collected from you as part
            of such merger, acquisition, sale, or other change of control.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">j.&emsp;Survival</span></h2>
            </li>
        </ol>
        <p class="c7 c20"><span class="c0"></span></p>
        <p class="c13"><span class="c0">All provisions of this Agreement which by their nature extend beyond the expiration or
            termination of this Agreement, including, without limitation, sections pertaining to suspension or termination,
            Dibbs Account cancellation, debts owed to Dibbs, general use of the Dibbs Platform, disputes with Dibbs, and
            general provisions in this Section 9, shall survive the termination or expiration of this Agreement.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">k.&emsp;Governing Law</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">You agree that the laws of the State of New York, without regard to principles of
            conflict of laws, will govern this Agreement and any claim or dispute that has arisen or may arise between you and
            Dibbs, except to the extent governed by federal law.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c3 c2">l.&emsp;Force Majeure</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">We shall not be liable for delays, failure in performance or interruption of service
            which result directly or indirectly from any cause or condition beyond our reasonable control, including but not
            limited to, significant market volatility, any delay or failure due to any act of God, act of civil or military
            authorities, act of terrorists, civil disturbance, war, strike or other labor dispute, fire, interruption in
            telecommunications or Internet services or network provider services, failure of equipment and/or software, other
            catastrophe or any other occurrence which is beyond our reasonable control and shall not affect the validity and
            enforceability of any remaining provisions.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span
                class="c19 c2 c11">m.&emsp;ESIGN Consent to Use Electronic Records and Signatures</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">We may be required by law to give you certain information in writing &ndash; which
            means you are entitled to receive it on paper. However, you agree that we may instead provide this information to
            you electronically. You also agree to our use of electronic records and signatures throughout our relationship
            with you.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">For purposes of this ESIGN consent &ldquo;Communications&rdquo; means each disclosure,
            notice, agreement, fee schedule, statement, record, document, and other information we provide to you, or that you
            sign, submit, or agree to at our request.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">In our sole discretion, the Communications we provide to you, or that you sign or
            agree to at our request, may be in electronic form (&ldquo;Electronic Records&rdquo;). We may also use electronic
            signatures and obtain them from you as part of our transactions with you.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">Electronic Records may be delivered to you in a variety of ways, including by email or
            by us making them available to you on the Dibbs Platform. We may always, in our sole discretion, provide you with
            any Communication via paper, even if you have chosen to receive it electronically.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">Sometimes the law, or our agreement with you, requires you to give us a written
            notice. You must still provide these notices to us on paper, unless we specifically tell you in another
            Communication how you may deliver that notice to us electronically.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">There may be Communications that by law we are not permitted to deliver to you
            electronically, even with your consent. So long as required by law, we will continue to deliver those
            Communications to you in writing. However, if the law changes in the future and permits any of those
            Communications to be delivered as Electronic Records, this ESIGN Consent will automatically cover those
            Communications as well.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">Your consent covers all Communications relating to any of the Services. You agree that
            we may satisfy any obligation to provide you with our Privacy Policy or other terms and conditions related to the
            Services, including this User Agreement, by keeping such documents available for review on dibbsmarketplace.com, or, with
            notice to you, on another website where we offer the Services.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span class="c0">You have the right to withdraw your consent at any time. Please be aware, however,
            that withdrawal of consent may result in the termination of your access to our Services. Your withdrawal of
            consent will become effective after we have had a reasonable opportunity to act upon it.</span></p>
        <p class="c7"><span class="c0"></span></p>
        <ol>
            <li class="c5">
            <h2 style="display:inline"><span class="c2 c11 c19">n.&emsp;Promotional Offerings</span></h2>
            </li>
        </ol>
        <p class="c7"><span class="c0"></span></p>
        <p class="c13"><span>Dibbs may from time to time offer promotions or other rewards to you and/or other users, which
            may include without limitation reduced fees, account credits, or exclusive drops (collectively, the
            &ldquo;</span><span class="c11">Promotional Offerings</span><span class="c0">&rdquo;). You understand and agree
            that any Promotional Offerings shall be offered, if at all, in Dibbs sole discretion, and Dibbs shall decide
            whether and in what amount any Promotional Offering may entitle you to receipt of any reward. Dibbs may use
            third-party vendors for certain Promotional Offerings, in which case you agree to any terms and conditions of the
            respective third-parties by participating in the Promotional Offering.</span></p>
        <div>
            <p class="c23 c29"><span class="c0"></span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLogo from '@/stories/misc/MainLogo.vue';

export default {
  name: 'UserAgreement',
  components: {
    MainLogo,
  },
  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'TERMS_AND_CONDITIONS_VIEWED',
      variables: {
        term: 'Upcoming User Agreement',
      },
    });
  },
};
</script>

<style src="@/assets/css/upcoming-user-agreement.css" scoped></style>
<style scoped>
.c36{max-width:468pt; padding: 15pt;}

/* Scrollbar style */
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
</style>
